module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Vacay","short_name":"vacay","start_url":"/","background_color":"#4e58ce","theme_color":"#4e58ce","display":"standalone","icon":"src/images/logo.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-firebase/gatsby-browser.js'),
      options: {"plugins":[],"features":{"auth":true,"analytics":true,"performance":true,"firestore":true,"database":false,"storage":false,"messaging":false,"functions":false},"credentials":{"apiKey":"AIzaSyCPRRXpTkg5SbkrXTZ3IalKdxYQPPi-B90","authDomain":"vacay-65de4.firebaseapp.com","databaseURL":"https://vacay-65de4.firebaseio.com","projectId":"vacay-65de4","storageBucket":"vacay-65de4.appspot.com","messagingSenderId":"314440641418","appId":"1:314440641418:web:819d9c995d8d7f32b2b673","measurementId":"G-XFYNQMHTV3"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
